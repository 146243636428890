<!--推广-任务-新建任务-->
<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-card title="任务描述" style="width:100%;min-height:100%;" :bordered="false">
                        <a-form-model layout="horizontal" style="width:800px;"
                                      ref="ruleForm"
                                      :rules="rules"
                                      :model="Tasks"
                                      :label-col="formItemLayout.labelCol"
                                      :wrapper-col="formItemLayout.wrapperCol">
                            <a-form-model-item label="任务名称" prop="Name">
                                <a-input placeholder="请输名称" v-model="Tasks.Name"></a-input>
                            </a-form-model-item>
                            <a-form-model-item label="开始时间"
                                               required
                                               prop="BeginTime">
                                <a-date-picker v-model="Tasks.BeginTime"
                                               show-time
                                               type="date"
                                               :disabled-date="disabledStartDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择开始时间"
                                               style="width:100%"
                                               @openChange="handleStartOpenChange" />
                            </a-form-model-item>
                            <a-form-model-item label="结束时间"
                                               required
                                               prop="EndTime">
                                <a-date-picker show-time
                                               style="width:100%"
                                               type="date"
                                               :disabled-date="disabledEndDate"
                                               format="YYYY-MM-DD HH:mm:ss"
                                               placeholder="选择结束时间"
                                               :open="endOpen"
                                               @openChange="handleEndOpenChange"
                                               v-model="Tasks.EndTime" />
                            </a-form-model-item>

                            <!--文章 H5链接-->
                            <a-form-model-item label="选择文章" v-if="Tasks.TaskType==7">
                                <a-input placeholder="点击选择文章" disabled v-model="tempNewsTitle" />
                                <a-button type="primary" value="点击选择" size="small" @click="showNews">   点击选择         </a-button>
                            </a-form-model-item>
                            <a-form-model-item label="选择商品" v-if="Tasks.TaskType==14">
                                <a-input placeholder="点击选择商品" disabled v-model="tempNewsTitle" />
                                <a-button type="primary" value="点击选择" size="small" @click="showGoods">   点击选择         </a-button>
                            </a-form-model-item>
                            <a-form-model-item label="H5链接" v-if="Tasks.TaskType==10" prop="H5Url">
                                <a-input placeholder="H5链接" v-model="Tasks.H5Url" />
                            </a-form-model-item>
                            <a-form-model-item label="H5链接分享标题" v-if="Tasks.TaskType==10" prop="ShareTitle">
                                <a-input placeholder="H5链接分享标题" v-model="Tasks.ShareTitle" />
                            </a-form-model-item>
                            <a-form-model-item label="H5链接分享缩率图" v-if="Tasks.TaskType==10" class="ant-form-item-required">
                                <a-upload name="image"
                                          :data="ParamData"
                                          list-type="picture-card"
                                          class="avatar-uploader"
                                          action="/FileServer/Upload"
                                          :fileList="Tasks.fileList"
                                          @change="imgChange">
                                    <div v-if="Tasks.fileList.length < 1 && this.$route.query.rowType !='select'">
                                        <a-icon type="plus" />
                                        <div class="ant-upload-text">上传图片</div>
                                    </div>
                                </a-upload>
                            </a-form-model-item>
                            <!--文章 H5链接-->


                            <a-form-model-item label="位置区域">
                                <a-switch v-model="IsLimitPlace" @click="postionChange">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                                <a-button type="primary" value="点击选择" @click="map_display" v-show="IsLimitPlace" size="small" style="margin-left:12px;">   点击选择         </a-button>
                            </a-form-model-item>

                            <a-form-model-item label="地址" v-show="IsLimitPlace">
                                <a-input placeholder="地址" type="textarea" disabled v-model="mapModel.tempaddress" />
                            </a-form-model-item>


                            <a-form-model-item label="区域外显示" v-show="IsLimitPlace">
                                <a-switch v-model="IsPermitOutSideDisplay">
                                    <a-icon slot="checkedChildren" type="check" />
                                    <a-icon slot="unCheckedChildren" type="close" />
                                </a-switch>
                            </a-form-model-item>
                            <a-form-model-item label="模板选择" style="margin-bottom:10px;">

                                <div class="yj-storecon-template" :data="templates">
                                    <div v-for="item in templates" v-bind:key="item.ID" @click="chooseTemplate(item.ID)" :class="{'active':item.ID==Tasks.TemplateID}">
                                        <img :src="item.BackgroudImg" />
                                    </div>
                                </div>

                            </a-form-model-item>
                            <a-card title="任务目标" style="width:100%;" :bordered="false">
                                <a-form-model-item style="margin-left:15%;">
                                    <a-form-model layout='inline'>
                                        <a-form-model-item label="阅读" prop="ReadTarget">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="Tasks.ReadTarget" />
                                        </a-form-model-item>
                                        <a-form-model-item label="拉新" prop="NewUserTarget">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="Tasks.NewUserTarget" />
                                        </a-form-model-item>
                                        <br />
                                        <a-form-model-item label="意向" prop="WantUserTarget">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="Tasks.WantUserTarget" />
                                        </a-form-model-item>
                                        <a-form-model-item label="成交" prop="ReportTarget">
                                            <a-input-number :min="0" placeholder="" style="width:70px" v-model="Tasks.ReportTarget" />
                                        </a-form-model-item>
                                    </a-form-model>
                                </a-form-model-item>

                            </a-card>
                            <a-card title="任务佣金" style="width:100%;" :bordered="false">
                                <a-form-model-item label="任务佣金(元)" prop="TaskBonus">
                                    <a-input-number :min="0" placeholder="" style="width:70px" type="number" v-model="Tasks.TaskBonus" />
                                </a-form-model-item>
                                <a-form-item label="名次">
                                    <div>
                                        <a-button type="primary" value="点击添加" @click="addRanking()" size="small">点击添加</a-button>
                                    </div>
                                    <div class="form-inline" :key="item.Rank" v-for="item in Tasks.Rewards">
                                        <!--<div v-if="item.IsDel==false">-->
                                        <!--v-if="item.IsDel==false"-->
                                        名次：
                                        <a-input type="number" class="margin-r" v-model="item.Rank" style="width:100px" step="1" min="1"
                                                 @change="RankChange(item)" @keyup="RankKeyUp(item)" />

                                        奖励：
                                        <a-input-number :min="0" v-model="item.Reward" style="width:100px" step="0.1"
                                                        @change="RewardChange(item)" />
                                        <a-button type="primary" style="background-color:red;margin-left:12px;border:none;" value="删除" @click="deleteCurrentRow(item)" size="small">删除</a-button>

                                    </div>

                                </a-form-item>
                                <a-form-model-item label="分享奖励" prop="SharePrice">
                                    <a-input-number :min="0"
                                                    style="width:70px"
                                                    :value="Tasks.SharePrice"
                                                    @change="SharePriceChange" />
                                </a-form-model-item>
                                <a-form-model-item label="分享奖励上限" prop="LimitMoney">
                                    <a-input-number :min="0"
                                                    style="width:70px"
                                                    :value="Tasks.LimitMoney"
                                                    @change="LimitMoneyChange" />
                                </a-form-model-item>
                            </a-card>

                            <a-card title="任务说明" style="width:100%;" :bordered="false">
                                <a-form-model-item label="任务说明">
                                    <Rich v-bind:value.sync="Tasks.Remark" rows="5"></Rich>
                                </a-form-model-item>
                                <a-form-model-item label="" class="textal_c">
                                    <div v-if="this.$route.query.rowType !='select'">
                                        <a-button type="primary" @click="save('ruleForm');">
                                            保存
                                        </a-button>
                                    </div>
                                </a-form-model-item>
                            </a-card>
                        </a-form-model>



                    </a-card>


                </div>
            </a-layout-content>
        </a-layout>
        <!--地图-->
        <a-modal v-model="mapModelDisplay"
                 title="选择活动区域"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="confirmAddress">
            <div style="width: 100%; height:auto;">
                <div id="map_title">
                    请以当前
                    <a href="javascript:void(0)" onclick="map_location();" style="color:red;font-weight:400;font-size:20px;">您的位置</a>为中心，选择本次活动范围。
                    <br>
                    <span>备注：可拖动红色中心标示，改变您当前位置</span>
                </div>
                <div id="map_sel">
                    <a-select :default-value="circlePath.radius" style="width: 120px" @change="radiusChange">
                        <a-select-option value="0">请选择活动区域</a-select-option>
                        <a-select-option value="5000">5公里</a-select-option>
                        <a-select-option value="10000">10公里</a-select-option>
                        <a-select-option value="15000">15公里</a-select-option>
                        <a-select-option value="20000">20公里</a-select-option>
                        <a-select-option value="30000">30公里</a-select-option>
                        <a-select-option value="50000">50公里</a-select-option>
                        <a-select-option value="100000">100公里</a-select-option>
                        <a-select-option value="-1">不限制</a-select-option>
                    </a-select>
                </div>
                <baidu-map class="bm-view" ak="E8f3c743837925ce120eb93417f478db" :center="mapModel.center" :zoom="mapModel.zoom" :scroll-wheel-zoom="true" @ready="mapReady">
                    <bm-marker :position="mapModel.center" :dragging="true" @dragend="dragend">
                    </bm-marker>
                    <bm-circle :center="circlePath.center" :radius="circlePath.radius" :editing="circlePath.editing" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2"></bm-circle>
                </baidu-map>
            </div>
        </a-modal>

        <!--文章-->
        <a-modal v-model="News.IsDisplay"
                 title="文章选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onNewsOk">
            <div style="width: 100%; height:auto;">
               
                            <ul class="form-search">
                                <li style="margin:0">
                                    <label>标题</label>
                                    <a-input placeholder="输入标题" v-model="News.Title"></a-input>
                                </li>
                                <!--<li>
            <label>资讯内容</label>
            <a-input placeholder="输入资讯内容" v-model="Tasks.Name"></a-input>
        </li>-->
                            </ul>
                       
                        <a-button type="primary" value="查询" @click="getNewsList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>

                    <div  id="boxdiv" style="padding-top:16px;">
                        <a-radio-group v-model="News.ChooseID" @change="onNewsChange" >
                            <table id="example2" class="table  table-hover">
                                <thead>
                                    <tr>
                                        <th width="40"></th>
                                        <th width="100">封面图</th>
                                        <th>标题</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="item in News.NewsList" :key="item.ID">
                                        <td>
                                            <a-radio :value="item.ID"></a-radio>
                                        </td>
                                        <td align="center">
                                            <img :src="item.TitleImg" height="80"  />
                                        </td>
                                        <td title="NewsTitle">{{item.NewsTitle}}</td>
                                        <!--<td style="display:none" title="ID">ID</td>-->
                                    </tr>

                                </tbody>
                            </table>
                        </a-radio-group>
                    </div>
                    <!--<ul class="pager" id="pagerlink">
                        <li>
                            <a class="nav-link" href="/SettingManager/SelNews?pageindex=1">首页</a>
                        </li>
                        <li>
                            <a class="nav-link" href="/SettingManager/SelNews?pageindex=1">上一页</a>
                        </li>
                        <li>
                            <a class="nav-link" href="/SettingManager/SelNews?pageindex=1">下一页</a>
                        </li>
                        <li>
                            <a class="nav-link" href="/SettingManager/SelNews?pageindex=1">未页</a>
                        </li>
                        <li style="color: #999;">
                            第 1/1 页   (共计 1 条)
                        </li>
                    </ul>-->
               
            </div>
        </a-modal>

        <!--商品-->
        <a-modal v-model="Goods.IsDisplay"
                 title="商品选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onGoodsOk">
            <div class="content" style="width: 100%; height:auto;">
                <form class="form-inline" id="newsList">
                    <ul class="form-search">
                        <li>
                            <label>标题</label>
                            <a-input placeholder="输入标题" v-model="Goods.Title"></a-input>
                        </li>
                    </ul>
                    <a-button type="primary" value="查询" @click="getGoodsList" size="small" style="margin-left:12px;">查询</a-button>
                </form>
                <div class="col-xs-12">
                    <div class="box" id="boxdiv">
                        <div class="mtb20"></div>
                        <a-radio-group v-model="Goods.ChooseID" @change="onGoodsChange" style="height:500px; width:800px; overflow-y:scroll;">
                            <table id="example2" class="table  table-hover">
                                <thead>
                                    <tr>
                                        <th width="80"></th>
                                        <th width="500" align="center">封面图</th>
                                        <th>商品名称</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in Goods.GoodsList" :key="item.ID">
                                        <td>
                                            <a-radio :value="item.ID"></a-radio>
                                        </td>
                                        <td align="center">
                                            <img :src="getImagePath(item.ProductExImage[0])" width="80" style="margin-right:10px;margin-top:10px;display:inline-block" />
                                        </td>
                                        <td title="NewsTitle">{{item.Name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </a-radio-group>
                    </div>
                </div>
            </div>
        </a-modal>


    </div>
</template>
<!--<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.config.js"></script>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.all.js"></script>-->
<script type="text/javascript">
    import util from "@/Plugin/util.js";
    import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
    import { BmCircle } from 'vue-baidu-map'
    import { BmMarker } from 'vue-baidu-map'
    import { message } from 'ant-design-vue'

    export default {
        data() {
            return {
                Goods: {
                    ChooseID: "-1",
                    IsDisplay: false,
                    Title: '',
                    GoodsList: []

                },
                News: {
                    ChooseID: "-1",
                    IsDisplay: false,
                    NewsList: [],
                    Title: ""
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                Tasks: {
                    CustomeID: "dsi201706150003",
                    AboutContent: null,
                    AboutType: null,
                    ReadTarget: 0,//阅读
                    ReportTarget: 0,//成交
                    ConsultTarget: 0,

                    Distance: 10,
                    IsLimitPlace: false,//位置区域
                    LimitLng: 0,
                    LimitLat: 0,

                    ReadPriceIsOpen: true,
                    ReadPrice: 0,
                    SharePrice: 0,
                    IsPermitOutSideDisplay: false,
                    NewUserTarget: 0, //拉新
                    WantUserTarget: 0,//意向
                    areadyGetCount: 0,
                    areadyCompleteCount: 0,
                    leaveTimeStr: "23小时后结束",
                    NewsID: 0,
                    H5Url: null,
                    IsShowNews: false,
                    IsShowH5: false,
                    IsShowCla: false,
                    NewsTitle: null,
                    ClassTitl: null,
                    CenterPointAddress: "",//地址
                    TempName: "",
                    Rewards: [
                    ],
                    UserTask: null,
                    EndDay: 0,
                    ID: this.$route.query.ID,
                    TaskType: this.$route.query.taskType,//任务类型 暂无
                    BeginTime: "",//开始时间
                    EndTime: "",//结束时间
                    TaskBonus: 0,//任务佣金
                    UserFlag: 1,
                    InputTime: "",
                    Remark: "",//任务备注
                    CustomerID: "dsi201706150003",
                    Name: "",//任务名称
                    TaskTarget: null,
                    IsNewerTask: null,
                    ActionID: "",
                    TemplateID: "",
                    LimitMoney: 0,//分享奖励上限
                    ShareTitle:"",
                    fileList:[],
                    ShareImg:""

                },
                IsLimitPlace: false,//临时转换-开启位置限制
                IsPermitOutSideDisplay:false, //临时转换-区域外是否显示
                templates: [],
                tempNewsTitle: '',
                circle: null,
                mapModelDisplay: false,
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: ""
                },
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233
                    },
                    radius: 500,
                    editing: false
                },
                gec: null,
                limitPlaceModel: {
                    IsLimitPlace: true,
                    PostionSpan: "开启",
                    CheckClass: "is-checked"
                },
                readPriceModel: {
                    ReadPriceIsOpen: true,
                    ReadPriceSpan: "开启",
                    CheckClass: "is-checked"
                },
                IsPermitOutSideDisplayModel: {
                    IsPermitOutSideDisplay:true,
                    IsPermitOutSideDisplaySpan: "显示",
                    CheckClass: "is-checked"
                },
                endOpen: false,
                rules: {
                    Name: [{ message: "请输入任务名称", required: true, trigger: "blur" }],
                    ReadTarget: [{ message: "阅读不能为空且为整数", type: "integer", required: true, trigger: "blur" }],
                    NewUserTarget: [{ message: "拉新不能为空且为整数", type: "integer", required: true, trigger: "blur" }],
                    WantUserTarget: [{ message: "意向目标不能为空且为整数", type: "integer", required: true, trigger: "blur" }],
                    ReportTarget: [{ message: "成交不能为空且为整数", type: "integer", required: true, trigger: "blur" }],
                    TaskBonus: [{ message: "请输入任务佣金", required: true, trigger: "blur" }],
                    H5Url:[{message:"请输入H5链接地址",required:true,trigger:"blur"}],
                    ShareTitle:[{message:"请输入H5链接分享标题地址",required:true,trigger:"blur"}],
                    SharePrice: [{ message: "请输入分享奖励", required: true, trigger: "blur" }],
                    LimitMoney: [{ message: "请输入分享奖励上限", required: true, trigger: "blur" }],
                    BeginTime: [{ message: "请输入开始时间", required: true, trigger: "change" }],
                    EndTime: [{ message: "请输入结束时间", required: true, trigger: "change" }]
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
            };
        },
        methods: {
           imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.Tasks.fileList = info.fileList;
            },
            getImagePath: function (data) {
                if (data != null) {
                    return data.ImagePath;
                } else {
                    return "";
                }
            },
            chooseTemplate: function (data) {
                var self = this;
                //self.CurrentTemplate.TemplateUrl = 'http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006&templateid=' + data;
                self.Tasks.TemplateID = data;
            },
            showNews: function () {
                var self = this;
                self.News.IsDisplay = true;
            },
            showGoods: function () {
                var self = this;
                self.Goods.IsDisplay = true;
            },
            onNewsChange(e) {
                var self = this;
                self.News.ChooseID = e.target.value;
            },
            onGoodsChange(e) {
                var self = this;
                self.Goods.ChooseID = e.target.value;
                //console.log(e.target.value);
            },
            onNewsOk: function () {
                var self = this;
                self.Tasks.NewsID = self.News.ChooseID;

                self.News.IsDisplay = false;

                //查找选择的文章明细
                var result = self.News.NewsList.filter(function (item, index) {
                    console.log(index);
                    return item.ID == self.Tasks.NewsID;
                });
                self.tempNewsTitle = result[0].NewsTitle;
                self.Tasks.NewsTitle = self.tempNewsTitle;
                //console.log(result);

            },
            onGoodsOk: function () {
                var self = this;
                self.Tasks.AboutContent = self.Goods.ChooseID;

                self.Goods.IsDisplay = false;

                //查找选择的文章明细
                var result = self.Goods.GoodsList.filter(function (item, index) {
                    console.log(index);
                    return item.ID == self.Goods.ChooseID;
                });
                self.tempNewsTitle = result[0].Name;
                //self.Tasks.NewsTitle = self.tempNewsTitle;
                //console.log(result);

            },
            deleteCurrentRow: function (obj) {
                var self = this;
                var index = self.Tasks.Rewards.findIndex(item => {
                    if (item.Rank == obj.Rank) {
                        return true
                    }
                })
                self.Tasks.Rewards.splice(index, 1);
            },
            addRanking: function () {
                var self = this;
                console.log('12345');
                console.log(self.Tasks.Rewards);

                var currentRow = {
                    TaskID: self.Tasks.ID,
                    Rank: self.Tasks.Rewards.length + 1,
                    Reward: 0,
                    IsDel: false
                };
                //console.log(currentRow);
                self.Tasks.Rewards.push(currentRow);
            },
            map_display: function () {
                var self = this;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.mapModelDisplay = false;
                //确认地理位置
            },
            mapReady: function ({ BMap, map }) {

                console.log(map);

                var self = this;
                self.gec = new BMap.Geocoder();

                self.Tasks.LimitLng = self.mapModel.center.lng;
                self.Tasks.LimitLat = self.mapModel.center.lat;
            },
            dragend: function (type) {

                var self = this;
                self.circlePath.center = type.point;
                self.Tasks.LimitLng = type.point.lng;
                self.Tasks.LimitLat = type.point.lat;

                self.gec.getLocation(type.point, function (rs) {


                    var addComp = rs.addressComponents;

                    var trainPlace = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;

                    var content = trainPlace;// "<br/><br/>经度：" + myPoint.lng + "<br/>纬度：" + myPoint.lat;



                    self.mapModel.tempaddress = content;
                    self.Tasks.CenterPointAddress = content;


                });


            },
            radiusChange: function (value) {
                var self = this;
                self.circlePath.radius = value;
                self.Tasks.Distance = value / 1000;
            },
            postionChange: function (val) {
                var self = this;
                if (val) {
                    self.IsLimitPlace = true;
                    self.limitPlaceModel.CheckClass = "is-checked";
                    self.limitPlaceModel.PostionSpan = "开启";
                    self.limitPlaceModel.IsLimitPlace = 1;

                } else {
                    self.IsLimitPlace = false;
                    self.limitPlaceModel.CheckClass = "";
                    self.limitPlaceModel.PostionSpan = "关闭";
                    self.limitPlaceModel.IsLimitPlace = 0;
                }
            },
            getTemplateData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetTemplateList",
                    data: {
                        type: self.Tasks.TaskType
                    },
                    OnSuccess: function (data) {
                        //根据编辑 新增状态，判断
                        if (self.Tasks.TemplateID != '') {
                            self.Tasks.TemplateID = data.data[0].ID;
                        }
                        self.templates = data.data;
                        console.log(self.Tasks.TemplateID)
                        console.log(self.templates)

                    }
                };
                http.Post(op);
            },
            getNewsList: function () {
                var self = this
                var op = {
                    url: "/MarketingModule/Material/NewsList",
                    data: {
                        NewsTitle: self.News.Title
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.News.NewsList = data.data.Data;
                            self.News.ChooseID = self.News.NewsList[0].ID;
                            self.News.IsDisplay = false;
                        }
                    }
                };
                http.Post(op);
            },
            LimitMoneyChange(value) {
                this.Tasks.LimitMoney = value;
            },
            SharePriceChange(value) {
                this.Tasks.SharePrice = value;
            },
            getGoodsList: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/Product/ProductList",
                    data: {
                        pageIndex: 1,
                        Online: -1,
                        name: self.Goods.Title
                    },
                    OnSuccess: function (data) {
                        self.Goods.GoodsList = data.data.Data;
                    }
                };
                http.Post(op);
            },
            getData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Task/TaskDetail",
                    data: {
                        ID: this.$route.query.id,
                        //  ID: self.Tasks.ID,
                        TaskType: self.Tasks.TaskType
                    },
                    OnSuccess: function (data) {
                        console.log(data);

                        // if (data.data == "True") {
                        self.Tasks.ReadTarget = data.data.ReadTarget;
                        self.Tasks.ReportTarget = data.data.ReportTarget;
                        self.IsLimitPlace = data.data.IsLimitPlace == 1 ? true : false;
                        self.IsPermitOutSideDisplay = data.data.IsPermitOutSideDisplay == 1 ? true : false;
                        self.Tasks.LimitLng = data.data.LimitLng;
                        self.Tasks.LimitLat = data.data.LimitLat;
                        self.mapModel.tempaddress = data.data.CenterPointAddress;
                        self.mapModel.center.lng = data.data.LimitLng;
                        self.mapModel.center.lat = data.data.LimitLat;
                        self.circlePath.center.lng = data.data.LimitLng;
                        self.circlePath.center.lat = data.data.LimitLat;
                        self.Tasks.CenterPointAddress = data.data.CenterPointAddress;
                        self.Tasks.NewUserTarget = data.data.NewUserTarget;
                        self.Tasks.WantUserTarget = data.data.WantUserTarget;
                        self.Tasks.CenterPointAddress = data.data.CenterPointAddress;
                        self.Tasks.ID = data.data.ID;
                        self.Tasks.TaskType = data.data.TaskType;
                        if (data.data.BeginTime == '/Date(-62135596800000)/') {
                            self.Tasks.BeginTime = null;
                        } else {
                        self.Tasks.BeginTime = util.TimeStamptoDateTime(data.data.BeginTime, 'yyyy-MM-dd hh:mm:ss')

                        }
                         if (data.data.EndTime == '/Date(-62135596800000)/') {
                            self.Tasks.EndTime =null;
                        } else {
                         self.Tasks.EndTime = util.TimeStamptoDateTime(data.data.EndTime, 'yyyy-MM-dd hh:mm:ss');

                        }

                        self.Tasks.TaskBonus = data.data.TaskBonus;
                        self.Tasks.Remark = data.data.Remark;
                        self.Tasks.Name = data.data.Name;
                        self.Tasks.LimitMoney = data.data.LimitMoney;
                        if (self.Tasks.LimitMoney == null) {
                            self.Tasks.LimitMoney = 0
                        }

                        self.Tasks.NewsID = data.data.NewsID;
                        self.tempNewsTitle = data.data.NewsTitle;
                        self.Tasks.NewsTitle = data.data.NewsTitle;
                        self.Tasks.H5Url = data.data.H5Url;
                        self.Tasks.ShareTitle = data.data.ShareTitle;

                        self.Tasks.TemplateID = data.data.TemplateID;

                        self.Tasks.Distance = data.data.Distance;
                        self.Tasks.ShareImg =data.data.ShareImg;
                        self.circlePath.radius = (data.data.Distance * 1000).toString();
                        //名次
                        self.Tasks.Rewards = data.data.Rewards;
                        if (self.Tasks.Rewards == null) {
                            self.Tasks.Rewards = [];
                        }
                        //H5图片
    console.log("***********");
    console.log(self.Tasks.TaskType);
    console.log(self.Tasks.ShareImg);
    console.log("***********");
                     if (self.Tasks.TaskType == "10" && self.Tasks.ShareImg != null&&self.Tasks.ShareImg != "") {
                            console.log(23232);
                            self.Tasks.fileList = [{
                                name: self.Tasks.ShareImg,
                                response: self.Tasks.ShareImg,
                                status: "done",
                                uid: "0",
                                url: self.Tasks.ShareImg
                            }];
                        }
                        //[{ID: "321_1",Rank: 1,Reward: 0,TaskID: 321,IsDel:false}];
                        // data.data.Rewards;
                    }
                };
                http.Post(op);
            },
            save: function(formName) {
                console.log(formName)
             var self = this;

                console.log(self.Tasks.TaskType);
                console.log(self.Tasks.fileList[0]);



                if (self.Tasks.AboutType && self.Tasks.fileList.length==0) {
                    alert("开始时间大于结束时间");
                    return false;
                }

                 if (self.Tasks.ReadTarget == 0 && self.Tasks.ConsultTarget == 0 && self.Tasks.ReportTarget == 0 && self.Tasks.NewUserTarget == 0 && self.Tasks.WantUserTarget == 0) {
                    alert("阅读目标、咨询目标、报名目标、拉新目标、意向目标不能同时为0");
                    return false;
                }
                if (self.Tasks.BeginTime > self.Tasks.EndTime) {
                    alert("开始时间大于结束时间");
                    return false;
                }

                if (self.Tasks.TaskType==10 && self.Tasks.fileList.length==0) {
                    alert("上传H5分享图片");
                    return false;
                }
     if (self.Tasks.TaskType==10 && self.Tasks.fileList.length==1) {
                self.Tasks.ShareImg= self.Tasks.fileList[0].response;
    }

                this.$refs[formName].validate(valid => {
                    if (valid) {


                        self.Tasks.IsLimitPlace = self.IsLimitPlace == true ? 1 : 0;
                        self.Tasks.IsPermitOutSideDisplay = self.IsPermitOutSideDisplay == true ? 1 : 0;
                        var op = {
                            url: "/MarketingModule/Task/TargetTask",
                            data: {
                                task: self.Tasks
                            },
                            OnSuccess: function (data) {
                                console.log(data);
                                var display = "新增成功";
                                if (self.Tasks.ID != null && self.Tasks.ID != "") {
                                    display = "编辑成功";
                                }
                                message.success(
                                    display,
                                    1.5,
                                    function () {
                                        self.$router.push({
                                            name: "Task_List", query: {

                                            }
                                        })
                                    }
                                );
                            }
                        };

                        http.Post(op);

                    } else {
                        return false;
                    }
                });
                //if (self.Tasks.TaskType == 7) {
                //    //var _newsId = $("#NewsID").val();
                //    if (self.Tasks.NewsID != "" && self.Tasks.NewsID != 0) {
                //        //self.Tasks.NewsID = _newsId;
                //    } else {
                //        alert("请选择文章！");
                //        return false;
                //    }
                //} else if (self.Tasks.TaskType == 10) {
                //    if (!self.Tasks.H5Url) {
                //        alert("请填写H5链接！");
                //        return false;
                //    }
                //} else if (self.Tasks.TaskType == 14) {

                //    if (self.Tasks.AboutContent != "" && self.Tasks.AboutContent != 0) {
                //        console.log('null');
                //    } else {
                //        alert("请选择班型！");
                //        return false;
                //    }
                //}

                //if (self.Tasks.IsLimitPlace == 1 && (self.Tasks.Distance == 0)) {
                //    alert("开启位置，半径距离不能为空！");
                //    return false;
                //}


                //if (self.Tasks.IsLimitPlace == 1 && (self.Tasks.LimitLng == 0 || self.Tasks.LimitLat == 0 || self.Tasks.LimitLng == "" || self.Tasks.LimitLat == "")) {
                //    alert("开启位置，中心坐标不能为空！");
                //    return false;
                //}
            },
            disabledStartDate(startValue) {
                const endValue = this.Tasks.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.Tasks.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            handleEndOpenChange(open) {

                this.endOpen = open;
            },
             RankChange(item) {
                console.log(item);
            },
            RankKeyUp(item) {
                console.log(item);
            },
            RewardChange(item) {
                console.log(item);
            },
        },
        components: {
            BaiduMap,
            BmCircle,
            BmMarker,
            Rich
        },
        mounted() {
            var self = this;
            self.getNewsList();
            self.getData();
            self.getGoodsList();
            self.getTemplateData();
        }
    }
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type='button'] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
      /*  filter: grayscale(80%);*/
    }
</style>
<style type="text/css">
    .content {
        min-height: 250px;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    /*列表CSS*/
    /*.col-xs-12 {
        width: 100%;
    }
    .box {
        border-top: none;
        box-shadow: none;
    }


    .box {
        position: relative;
        border-radius: 3px;
        background: #ffffff;
        border-top: 3px solid #d2d6de;
        margin-bottom: 20px;
        width: 100%;
        box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    }*/
    .box {
        border-top: none;
        box-shadow: none;
    }

    .right-btn {
        margin-top: 18px;
    }

    .line-btn {
        border: 1px solid #32a9ff;
        color: #32a9ff;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
    }

    .gray-line-btn {
        border: 1px solid #e5e5e5;
        color: #666;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
        margin-left: 20px;
    }

    .gray-line-btn {
        cursor: pointer
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

        .solid-btn:hover {
            background: #1b99f5;
            cursor: pointer;
            color: #fff
        }

    .num {
        font-size: 26px;
        color: #32a9ff;
        padding: 5px;
        font-weight: 500;
    }

    .inners {
        padding: 10px;
        border: 1px solid #f3f4f5
    }

    .search {
        overflow: hidden;
        background: #fbfbfb;
        margin: 10px auto;
        padding: 10px;
    }

    .line-btn:hover {
        background: #32a9ff;
        color: #fff;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        cursor: pointer;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    /* .single-row {
    } */

    .mtb5 {
        margin: 5px 0
    }

    .mtb10 {
        margin: 10px 0;
    }

    .mtb20 {
        margin: 20px 0;
    }

    .tr-middle td {
        vertical-align: middle;
    }

    body {
        background: #f3f5f5;
        font-family: Arial,Helvetica,sans-serif,"Microsoft YaHei","ËÎÌå";
        font-size: 15px;
    }

    .sys-name {
        color: #00a2e9;
        margin: 0;
        padding: 0;
        font-size: 25px;
        font-weight: 500;
        height: 68px;
        line-height: 68px;
    }

    .right-opt {
        height: 68px;
        line-height: 68px;
        cursor: pointer;
    }

    .top-top {
        margin-bottom: 10px;
        background: #fff;
        border-bottom: solid 1px #E0E5E5;
    }

    .top-center {
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-wrapper {
        background: #fff;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0
    }

    .box-header {
        border-bottom: 1px solid #f3f5f5;
        padding: 0 10px;
    }

        .box-header > .box-title {
            height: 62px;
            line-height: 62px;
        }

    .top-center a[href] {
        color: #32a9ff;
    }

    a {
        color: #32a9ff;
    }

    .pager li > a {
        border: none;
    }

    .main-right-search {
        padding: 20px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fbfbfb;
        padding-top: 0;
    }

    .form-search li {
        float: left;
        overflow: hidden;
        margin-right: 17px;
        width: 350px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: solid 1px #E0E5E5;
        border-radius: 4px;
        -moz-border-radius: 4px;
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
    }

        .form-search li label {
            float: left;
            width: 110px;
            color: #666;
            text-align: center;
            border-right: solid 1px #E0E5E5;
        }

        .form-search li input {
            float: left;
            border: none;
            background: none;
            outline: none;
            text-indent: 6px;
            -webkit-appearance: none;
            background-color: #ffffff;
            width: 235px;
            height: 26px;
            color: #333333;
            line-height: 28px;
        }

    .form-search-margin {
        margin-top: 12px;
    }

    .form-search li .inline-text {
        padding-left: 5px;
    }

    .pager {
        padding-left: 0;
        margin: 20px 0;
        text-align: center;
        list-style: none;
    }

        .pager li {
            display: inline;
        }

        .pager ul li {
            list-style: none outside none;
        }

        .pager li > a {
            border: none;
        }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            /*border: 1px solid #ddd;*/
            border-radius: 15px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }
</style>
